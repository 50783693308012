<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="back-btn" @click="goBack">
      <img src="@/assets/img/back-btn.png" alt="back" />
    </div>
    <div class="title">{{ title }}</div>
    <div class="circle"></div>
    <form class="login-content" @submit.prevent="onSubmit">
      <div class="input-block">
        <input
          v-model.trim="phone"
          type="tel"
          maxlength="13"
          autofocus
          placeholder="Masukkan nomor handphone dengan awalan 08"
        />
      </div>
      <div v-if="showPasswordInput" class="input-block">
        <input
          v-model.trim="password"
          maxlength="8"
          type="tel"
          class="password"
          :placeholder="tipText"
        />
      </div>
      <div class="input-block">
        <div class="input">
          <input
            v-model.trim="verifyCode"
            type="tel"
            maxlength="4"
            placeholder="Masukkan 4 digit angka kode verifikasi"
          />
          <div v-if="isGetCode" @click="getVerifyCode">
            Dapatkan kode verifikasi
          </div>
          <div v-else>{{ countDown }}s</div>
        </div>
      </div>
      <button
        type="submit"
        :class="['confirm-btn', showPasswordInput ? 'near' : 'far']"
      >
        {{ buttonText }}
      </button>
    </form>
    <div class="fan"></div>

    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
      class="cancel-loan-dialog"
    >
      <h3 class="verify-title">Diperlukan verifikasi</h3>
      <div class="verify-body">
        <div class="verify-img-block">
          <div class="verify-img">
            <img ref="verifyImg" />
          </div>
          <img
            class="refresh-btn"
            @click="refreshVerifyCode"
            src="@/assets/img/refresh-icon.png"
            alt="refresh"
          />
        </div>
        <div class="verify-input-box">
          <div v-for="(item, index) in verifyImgCodes" :key="index">
            <input
              :ref="`input${index}`"
              v-model="item.value"
              type="tel"
              @input="onInput(index)"
              @keyup.delete="onDelete(index)"
              maxlength="1"
            />
          </div>
          <p class="error-info">{{ errorInfo }}</p>
        </div>
        <button type="button" class="verify-confirm-btn" @click="onVerify">
          Yakin
        </button>
      </div>
      <img
        class="close-icon"
        src="@/assets/img/verify-close-icon.png"
        alt="close"
        @click="onCloseDialog()"
      />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword, validateVerifyCode } from '@/utils/validator'
import dialogVisible from '@/mixins/dialogVisible'
import { baseURL } from '@/config'

Vue.use(Toast)
const maxTime = 150
let timer
const clearTimer = () => {
  timer && clearTimeout(timer) && (timer = null)
}
const titleMap = {
  forgetPassword: 'Lupa Password',
  messageLogin: 'Login dengan SMS',
  register: 'Daftar'
}
const buttonText = {
  forgetPassword: 'Yakin',
  messageLogin: 'Login',
  register: 'Daftar'
}
const apiMap = {
  forgetPassword: 'usdrerForgetPassword',
  messageLogin: 'usdrerLogin',
  register: 'usdrerRegistration'
}

const messageTypeMap = {
  forgetPassword: 3,
  messageLogin: 2,
  register: 1
}

export default {
  name: 'LoginForm',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mixins: [dialogVisible],
  data () {
    return {
      phone: '',
      password: '',
      verifyCode: '',
      isGetCode: true,
      countDown: maxTime,
      verifyImg: '',
      verifyImgCodes: [
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        }
      ],
      errorInfo: ''
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {
    title () {
      return titleMap[this.type]
    },
    buttonText () {
      return buttonText[this.type]
    },
    showPasswordInput () {
      return this.type === 'forgetPassword' || this.type === 'register'
    },
    tipText () {
      if (this.type === 'register') {
        return 'Atur 8 digit password'
      } else {
        return 'Masukkan 8 digit angka password'
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.getImgVerifyCode()
          this.$refs.input0[0].focus()
        })
      }
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    validatePhoneNumber () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      return true
    },
    validate () {
      if (!this.validatePhoneNumber()) return false
      if (this.showPasswordInput) {
        if (!this.password) {
          Toast(this.tipText)
          return false
        }
        if (!validatePassword(this.password)) {
          Toast('Password salah')
          return false
        }
      }
      if (!this.verifyCode) {
        Toast('Masukkan 4 digit angka kode verifikasi')
        return false
      }
      if (!validateVerifyCode(this.verifyCode)) {
        Toast('Kode verifikasi salah')
        return false
      }
      return true
    },
    startCountDown () {
      if (this.countDown <= 0) {
        this.reset()
      } else {
        timer = setTimeout(() => {
          this.countDown--
          this.startCountDown()
        }, 1000)
      }
    },
    getVerifyCode () {
      if (!this.validatePhoneNumber()) return
      const postData = {
        usdrerPhone: this.phone,
        tydrpe: messageTypeMap[this.type],
        smdrsType: 1
      }
      this.imgVerifyCode && (postData.imdrgCode = this.imgVerifyCode)
      request.post('usdrersendMessages', postData)
        .then(() => {
          Toast('Kode verifikasi terkirim. Silakan cek')
          this.countDown = maxTime
          this.isGetCode = false
          this.startCountDown()
        })
        .catch(err => {
          if (err.code === 504) {
            this.visible = true
          }
          this.reset()
        })
    },
    getImgVerifyCode () {
      this.$refs.verifyImg.src = `${baseURL}usdrergetGraphic?usdrerPhone=${this.phone}&t=${+Date.now()}`
    },
    reset () {
      clearTimer()
      this.isGetCode = true
      this.countDown = maxTime
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerPhone: this.phone,
        smdrs_Verificationcode: this.verifyCode,
        smdrsType: 1
      }
      if (this.type === 'forgetPassword') {
        postData.usdrerPassword = this.password
      } else if (this.type === 'messageLogin') {
        postData.lodrginType = 2
      } else if (this.type === 'register') {
        const implementorid = localStorage.getItem('implementorid')
        const apitype = localStorage.getItem('apitype')

        if (implementorid) {
          postData.implementorid = implementorid
        }
        postData.apitype = apitype || 1
        postData.usdrerPassword = this.password
        postData.prdromotionChannel = 'AppStore'
      }
      request.post(apiMap[this.type], postData)
        .then(res => {
          if (this.type === 'forgetPassword') {
            const { query } = this.$route
            this.$router.push({
              path: '/login',
              query
            })
          } else if (this.type === 'register' || this.type === 'messageLogin') {
            this.$store.commit('recordInfo', {
              type: 'userSKInfo',
              value: res
            })
            localStorage.setItem('userSKInfo', JSON.stringify(res))
            this.$router.push('/home1')
          }
        })
        .finally(() => {
          this.flag = false
        })
    },
    onVerify () {
      this.imgVerifyCode = ''
      this.verifyImgCodes.forEach(item => {
        this.imgVerifyCode = this.imgVerifyCode + item.value
      })
      const postData = {
        usdrerPhone: this.phone,
        grdraphicCode: this.imgVerifyCode
      }
      request.post('usdrercheckGraphic', postData)
        .then(res => {
          Toast('Verifikasi Berhasil')
          this.visible = false
        }).catch(() => {
          this.getImgVerifyCode()
        })
    },
    refreshVerifyCode () {
      this.getImgVerifyCode()
    },
    onInput (index) {
      if (this.verifyImgCodes[index].value && index < 3) {
        console.log('onInput', index)
        this.$refs[`input${index + 1}`][0].focus()
      }
    },
    onDelete (index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`][0].focus()
        console.log('onDelete', index)
      }
    }
  },
  deactivated () {
    this.reset()
  }
}
</script>

<style scoped lang="less">
.login-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130px;
  height: 250px;
  background: url(../assets/img/forget-password-bg.png) no-repeat;
  background-size: cover;
  z-index: -1;
}

.back-btn {
  width: 24px;
  height: 40px;
  padding-top: 67px;
  padding-left: 41px;

  img {
    width: 100%;
    height: 100%;
  }
}

.title {
  margin-top: 29px;
  margin-left: 70px;
  line-height: 67px;
  font-size: 48px;
  color: #1c2f51;
}

.circle {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-top: 72px;
  margin-right: 88px;
  margin-bottom: 16px;
  float: right;
  background-color: #1663ff;
}

.login-content {
  clear: both;
}

.input-block {
  padding-left: 70px;
  padding-right: 70px;
  margin-top: 22px;

  input {
    width: 100%;
    height: 100px;
    line-height: 100px;
    border: none;
    border-radius: 50px;
    padding-left: 30px;
    margin-top: 22px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    background-color: #eee;

    &::placeholder {
      color: #999;
      font-size: 24px;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 100px;
    line-height: 100px;
    border-radius: 50px;
    margin-top: 22px;
    background-color: #eee;

    input {
      flex: 1;
      margin-top: 0;
    }

    div {
      width: 258px;
      height: 100%;
      line-height: 100px;
      border-radius: 50px;
      font-size: 30px;
      color: #fff;
      text-align: center;
      background-color: #1663ff;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.confirm-btn {
  .submit-btn;
  margin-top: 48px;
  margin-bottom: 102px;
  font-size: 36px;
}

.fan {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-top-left-radius: 100px;
  background-color: #1663ff;
}

.verify-title {
  line-height: 42px;
  padding-top: 49px;
  font-size: 30px;
  color: #1c2f51;
  text-align: center;
}

.verify-body {
  margin-left: 72px;
  margin-right: 72px;
}

.verify-img-block {
  display: flex;
  align-items: center;
  margin-top: 68px;
  height: 144px;
}

.verify-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid #1663ff;
  border-radius: 8px 0 0 8px;
  box-sizing: border-box;

  img {
    height: 100%;
  }
}

.refresh-btn {
  width: 78px;
  height: 100%;
  cursor: pointer;
}

.verify-input-box {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  div {
    flex: 1;

    &:nth-child(n + 2) {
      margin-left: 64px;
    }
  }

  input {
    width: 100%;
    line-height: 88px;
    padding: 0;
    border: 1px solid #1663ff;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    text-align: center;
  }

  .error-info {
    margin-top: 8px;
    font-size: 24px;
    color: #ee83ab;
  }
}

.verify-confirm-btn {
  width: 546px;
  height: 88px;
  line-height: 88px;
  border-radius: 8px;
  margin-top: 32px;
  margin-bottom: 54px;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  background-color: #1663ff;
}

.close-icon {
  position: absolute;
  top: 25px;
  right: 24px;
  width: 78px;
  height: 78px;
}
</style>
